
import Vue from 'vue';

export default Vue.extend({
  name: 'Auth',

  created () {
    // this.$store.commit('setDarkMode', true)
  },

  destroyed () {
    // this.$store.commit('setDarkMode', false)
  }
});
